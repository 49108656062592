import {
    createStyles,
    Link,
    makeStyles,
    Theme,
    Typography,
  } from "@material-ui/core";
  import React from "react";
  import {
    Footer,
    Header
  } from "../components";  

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      container: {
        backgroundColor: "#FFF",
        display: "flex",
        flexGrow: 1,
      },
      content: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
        margin: "20px 0px"
      },
      paper: {
        width: "80%",
        marginTop: 50,
        borderRadius: 15,
        backgroundColor: "#fff",
        padding: theme.spacing(4),
        "& > h4": {
          fontSize: "2rem",
          marginBottom: theme.spacing(5),
        },
        "& > h3": {
          fontSize: "2.5rem",
          marginBottom: theme.spacing(3),
        },
        "& > p": {
          marginBottom: theme.spacing(6),
        },
      },
    })
  );
  
  const Privacy = () => {
    const classes = useStyles();
  
    return (
      <>
        <div className={classes.container}>
          <Header noSub/>
  
          <div className={classes.content}>
            <div className={classes.paper}>
              <Typography variant="h3">Privacy Policy</Typography>
  
              <Typography variant="body1">
                This privacy policy governs your use of the beta software
                application flourishzone (“Application”) for mobile devices that
                was created by Flourish Zone Ltd. Flourishzone is an interactive
                coaching app that helps leaders to continuously increase the
                performance, wellbeing and resilience of their people and
                organisation. In flourishzone, all your data is secure and treated
                as entirely confidential.
              </Typography>
              <Typography variant="h3">
                What information does the Application obtain and how is it used?
              </Typography>
              <Typography variant="h4">User Provided Information</Typography>
              <Typography variant="body1">
                This privacy policy governs your use of the beta software
                application flourishzone (“Application”) for mobile devices that
                was created by Flourish Zone Ltd. Flourishzone is an interactive
                coaching app that helps leaders to continuously increase the
                performance, wellbeing and resilience of their people and
                organisation. In flourishzone, all your data is secure and treated
                as entirely confidential.
              </Typography>
  
              <Typography variant="h4">
                Automatically Collected Information
              </Typography>
              <Typography variant="body1">
                In addition, the Application may collect certain information
                automatically, including, but not limited to, the type of mobile
                device you use and information about the way you use the
                Application.
              </Typography>
  
              <Typography variant="h3">
                Does the Application collect precise real time location
                information of the device?
              </Typography>
              <Typography variant="body1">
                This Application does not collect precise information about the
                location of your mobile device.
              </Typography>
  
              <Typography variant="h3">
                Do third parties see and/or have access to information obtained by
                the Application?
              </Typography>
              <Typography variant="body1">
                This Application does not collect precise information about the
                location of your mobile device.
              </Typography>
  
              <Typography variant="h3">
                Do third parties see and/or have access to information obtained by
                the Application?
              </Typography>
  
              <Typography variant="body1">
                Only aggregated, anonymized data is periodically transmitted to
                external services to help us improve the Application and our
                service. We will share your information with third parties only in
                the ways that are described in this privacy statement. We may
                disclose User Provided and Automatically Collected Information: as
                required by law, such as to comply with a subpoena, or similar
                legal process; when we believe in good faith that disclosure is
                necessary to protect our rights, protect your safety or the safety
                of others, investigate fraud, or respond to a government request;
                with our trusted services providers who work on our behalf, do not
                have an independent use of the information we disclose to them,
                and have agreed to adhere to the rules set forth in this privacy
                statement. if Flourish Zone Ltd is involved in a merger,
                acquisition, or sale of all or a portion of its assets, you will
                be notified via email and/or a prominent notice on our Web site of
                any change in ownership or uses of this information, as well as
                any choices you may have regarding this information.
              </Typography>
  
              <Typography variant="h3">What are my opt-out rights?</Typography>
  
              <Typography variant="body1">
                You can stop all collection of information by the Application
                easily by uninstalling the Application. You may use the standard
                uninstall processes as may be available as part of your mobile
                device or via the mobile application marketplace or network. You
                can also request to opt-out via email, at{" "}
                <Link href="mailto:adrienne.percival@flourish.zone">
                  adrienne.percival@flourish.zone.
                </Link>
              </Typography>
  
              <Typography variant="h3">
                Data Retention Policy, Managing Your Information
              </Typography>
  
              <Typography variant="body1">
                We will retain User Provided data for as long as you use the
                Application and for a reasonable time thereafter. We will retain
                Automatically Collected information for up to 24 months and
                thereafter may store it in aggregate. If you’d like us to delete
                User Provided Data that you have provided via the Application,
                please contact us at privacy@applicationsite.com and we will
                respond in a reasonable time. Please note that some or all of the
                User Provided Data may be required in order for the Application to
                function properly.
              </Typography>
  
              <Typography variant="h3">Children</Typography>
  
              <Typography variant="body1">
                We do not use the Application to knowingly solicit data from or
                market to children under the age of 13. If a parent or guardian
                becomes aware that his or her child has provided us with
                information without their consent, he or she should contact us at
                privacy@applicationsite.com. We will delete such information from
                our files within a reasonable time.
              </Typography>
  
              <Typography variant="h3">Security</Typography>
  
              <Typography variant="body1">
                We are concerned about safeguarding the confidentiality of your
                information. We provide physical, electronic, and procedural
                safeguards to protect information we process and maintain. For
                example, we limit access to this information to authorized
                employees and contractors who need to know that information in
                order to operate, develop or improve our Application. Please be
                aware that, although we endeavour to provide reasonable security
                for information we process and maintain, no security system can
                prevent all potential security breaches.
              </Typography>
  
              <Typography variant="h3">Changes</Typography>
  
              <Typography variant="body1">
                This Privacy Policy may be updated from time to time for any
                reason. We will notify you of any changes to our Privacy Policy by
                posting the new Privacy Policy here and informing you via email or
                text message. You are advised to consult this Privacy Policy
                regularly for any changes, as continued use is deemed approval of
                all changes. You can check the history of this policy by clicking
                here.
              </Typography>
  
              <Typography variant="h3">Your Consent</Typography>
  
              <Typography variant="body1">
                By using the Application, you are consenting to our processing of
                your information as set forth in this Privacy Policy now and as
                amended by us.
              </Typography>
  
              <Typography variant="h3">Contact us</Typography>
  
              <Typography variant="body1">
                If you have any questions regarding privacy while using the
                Application, or have questions about our practices, please contact
                us via email at{" "}
                <Link href="mailto:adrienne.percival@flourish.zone">
                  adrienne.percival@flourish.zone.
                </Link>
              </Typography>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  };
  
  export default Privacy;